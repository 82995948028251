<div class="container">
  <h3 mat-dialog-title>{{dialogParam.title || 'Are you sure to delete?'}}</h3>
  <div mat-dialog-content *ngIf="!dialogParam.text && !dialogParam.textHtml">
    Id: {{dialogParam.data.id}}
  </div>
  <div mat-dialog-content *ngIf="dialogParam.text">
    {{dialogParam.text}}
  </div>
  <div mat-dialog-content *ngIf="dialogParam.textHtml" [innerHTML]="dialogParam.textHtml">
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="true" (click)="onDelete()" i18n>Delete</button>
    <button mat-raised-button (click)="onNo()" i18n>Cancel</button>
  </div>
</div>
