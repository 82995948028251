import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BackendData, DialogParam, DialogResult, DialogResultState} from "@app/_model";

@Component({
  selector: 'app-delete.dialog',
  templateUrl: './delete.dialog.component.html',
  styleUrls: ['./delete.dialog.component.css'],
  standalone: false
})
export class DeleteDialogComponent<Type extends BackendData> implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DeleteDialogComponent<Type>>,
    @Inject(MAT_DIALOG_DATA) public dialogParam: DialogParam<Type>
  ) {
    //console.debug('dialogParam:', dialogParam);
  }

  public ngOnInit(): void {
    this.dialogRef.backdropClick().subscribe(event => {
      this.onCancel();
    });
  }

  @HostListener('document:keydown.escape', ['$event'])
  protected onCancel(): void {
    //console.debug('onCancel()');
    this.dialogRef.close(new DialogResult(DialogResultState.cancel));
  }

  protected onNo(): void {
    //console.debug('onNo()');
    this.dialogRef.close(new DialogResult(DialogResultState.cancel));
  }

  protected onDelete(): void {
    //console.debug('onDelete()');
    this.dialogRef.close(new DialogResult(DialogResultState.ok, this.dialogParam.data));
  }
}
